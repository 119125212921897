.reimagine-radio {
  svg {
    height: 30px;
    width: 30px;
  }
}

.reimagine-radio:hover {
  cursor: pointer;
}
