.reimagine-recording {
  height: calc(100vh - 64px);
}

.reimagine-recording-midiVisualizer {
  height: 100%;
}

.reimagine-recording-ios {
  left: 8px;
  font-size: 8px;
  top: 54px;
  position: absolute;
}

.reimagine-recording-buttons {
  position: absolute;
  width: 100%;
  margin-top: 8px;
  @extend .reimagine-colorProfile1;
  background-color: #c1b18544;
  max-width: 1280px;

  svg {
    @extend %reimagine-default-icon;
  }

  .reimagine-recording-buttons-uploadLast {
    position: absolute;
  }

  .reimagine-recording-buttons-center {
    text-align: center;
  }

  .reimagine-recording-buttons-mixer {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media (max-width: 584px) {
  .reimagine-recording-buttons {
    svg {
      margin: 12px;
    }
  }
}
