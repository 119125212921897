@import "navigation/navigation";
@import "recording/recording";
@import "settings/settings";
@import "listen/listen";
@import "about/about";
@import "pieces/pieces";
@import "create-mix/create-mix";
@import "create-mix/recording-selection";
@import "arrangement/arrangement";
@import "piece/add-new-arrangement";
@import "piece/piece";
@import "pieces/add-new-piece";

@import "small-components/checkbox";
@import "small-components/radio-button";
@import "small-components/section";
@import "small-components/audios";

.reimagine {
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.reimagine-mainArea {
  padding-top: 64px;
  height: calc(100% - 64px);
}

.reimagine-mainArea-spinner {
  margin: 24px;
  text-align: center;
}

body {
  margin: 0;
  color: $charcoal;
}

html,
body,
#root,
.reimagine {
  height: 100%;
}

.reimagine-button {
  font-size: 16px;
  line-height: 19px;
  min-width: 16px;
  height: 48px;
  padding: 15px 16px 14px 16px;
  border: 1px solid $charcoal;
  border-radius: 8px;
}

.reimagine-list {
  margin: 0;
  padding: 0;
  li {
    position: relative;
    padding: 7px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    list-style-type: none;
    display: inline-block;
    width: 100%;

    @extend .reimagine-colorProfile3;
  }
}
