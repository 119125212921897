.reimagine-pieces-list {
  li {
    height: 40px;
    line-height: 40px;
    text-align: center;

    div {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }
}
