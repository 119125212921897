.reimagine-checkbox {
  svg {
    height: 30px;
    width: 30px;
  }
}

.reimagine-checkbox:hover {
  cursor: pointer;
}
