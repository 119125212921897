.reimagine-audios {
  p {
    padding: 24px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  li {
    // HACKY but unsets it from the global rules...
    height: initial;
    line-height: initial;
    text-align: initial;

    .reimagine-audios-item-text {
      text-overflow: ellipsis;
      padding-left: 14px;
      overflow: hidden;
      max-width: 60%;
      white-space: nowrap;
    }

    .reimagine-audios-item-icons {
      position: absolute;
      right: 0;
      top: 0;

      .reimagine-audios-startMix {
        position: absolute;
        right: 64px;
        top: 24px;
      }

      svg {
        @extend %reimagine-default-icon;
        height: 28px;
        width: 28px;
        margin: 20px 12px;
      }
    }
  }
}

.reimagine-list-empty {
  margin: 16px;
}

.reimagine-audios-notLoggedIn {
  margin: 0 16px;
}
